import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import { keyBy } from 'lodash-es';
import { firstBy } from 'thenby';

import { reportError } from 'lib/error-reporting';
import * as api from 'lib/api';
import * as jobsApis from 'lib/api/jobs';
import { findApplicableRateCards, fetchMatchingCostCentre } from 'lib/helpers-metadata';
import { convertFromUtcToTimezone } from 'lib/helpers-time';
import { isFeatureOn } from 'lib/features';

import { shiftFieldMappings } from 'config/shiftFieldMappings';
import { featureFlags } from 'config/featureFlags';

import * as createDraftShiftActions from 'reducers/createDraftShifts';
import { addShiftsToList } from 'reducers/jobs';
import * as createShiftsActions from 'reducers/createShifts';
import * as jobsActions from 'reducers/jobs';
import * as templateActions from 'reducers/templates';

import { getFirstGrade, getGradeOptions, getSpeciality, getSpecialityOptions, getRoleOptions } from 'routes/Jobs/rgs-helpers';
import { computeNewShiftTimes } from 'routes/Jobs/date-time-field-helper';
import { calculateStartAndEndTimesFromTemplateShift, getTemplateMandatoryFields, createDraftFromPublishedShift, addResponseDataToDraftShift, createApplicableCandidatesSelectList, validateHourlyRate } from 'routes/Jobs/helpers';
import ClashesFound from 'routes/Jobs/DraftShiftsModal/ClashesFound';

function buildMessage(shift) {
  let message = null;
  if (shift.clashingShifts) {
    message = ReactDOMServer.renderToStaticMarkup(
      <div style={{ textAlign: 'center' }}>
        <ClashesFound clashingShifts={shift.clashingShifts} candidateName={shift.candidateName} />
      </div>,
    );
  } else if (shift.error) {
    message = ReactDOMServer.renderToStaticMarkup(<p>{shift.error}</p>);
  }
  return message;
}

function checkForClashingBookings(draftShiftKeys, draftShifts) {

  const draftShiftsToCreate = draftShiftKeys.map(draftKey => ({ draftKey, ...draftShifts[draftKey] }));
  const draftShiftsWithClashes = {};

  draftShiftsToCreate.forEach((draftShift) => {
    const clashingDraft = draftShift.candidate ? draftShiftsToCreate.find(({ draftKey, candidate, startTime, endTime }) => (
      draftShift.draftKey !== draftKey &&
      draftShift.candidate?.id === candidate?.id &&
      draftShift.endTime.slice(0, 16) > startTime.slice(0, 16) &&
      draftShift.startTime.slice(0, 16) < endTime.slice(0, 16)
    )) : null;

    if (clashingDraft) draftShiftsWithClashes[clashingDraft.draftKey] = { ...draftShift, status: 'shiftClash', response: 'The staff member is assigned to a shift that clashes with another shift selected.' };
  });

  return draftShiftsWithClashes;
}

export function createShiftsFromDrafts(draftShiftKeys) {
  return async (dispatch, getState) => {

    const draftShifts = getState().createShifts.draftShifts;
    const draftsToPublish = {};

    // Check drafts for clashes
    const draftShiftsWithClashes = checkForClashingBookings(draftShiftKeys, draftShifts);

    // Update any clashing drafts in redux
    if (Object.keys(draftShiftsWithClashes).length) {
      dispatch(createShiftsActions.addMultipleDraftShifts(draftShiftsWithClashes));
      return;
    }

    draftShiftKeys.forEach((key) => {
      if (draftShifts[key]) draftsToPublish[key] = draftShifts[key];
    });

    try {
      dispatch(createShiftsActions.isCreatingShifts());

      const response = await jobsApis.createShifts(draftsToPublish);

      if (response.success && response.shiftSummaries) {

        // Remove draft shifts and add new shifts to list
        dispatch(createShiftsActions.removeMultipleDraftShifts(draftShiftKeys));
        dispatch(jobsActions.unselectSpecifiedShifts(draftShiftKeys));
        dispatch(addShiftsToList(response.shiftSummaries));

        // The shape needs to match the draft shift in Redux so that the data renders correctly when it changes into a shift
        const recentlyCreatedShifts = {};
        Object.values(response.shiftSummaries).forEach((shift) => {

          recentlyCreatedShifts[shift.key] = {
            ...shift,
            booked: shift.booked ?? (shift.candidate ? 1 : 0),
            shiftNumber: shift.shiftNumber,
            candidateName: shift.candidateName ?? (shift.candidates ? Object.values(shift.candidates)[0] : null),
            isCreated: true,
            key: shift.key,
            isValid: shift.isValid,
          };
        });

        dispatch(createShiftsActions.upsertRecentlyCreatedShifts(recentlyCreatedShifts));
        dispatch(createShiftsActions.createShiftsSuccess());
      }

      // If shifts failed validation, add response data to draft shifts
      if (response.validatedShifts && response.failedShifts && response.humanReadableErrorMessage) {
        const draftShiftsWithResponseData = {};
        const shiftsFromResponse = keyBy([...response.validatedShifts, ...response.failedShifts], 'draftKey');

        Object.entries(draftsToPublish).forEach(([draftKey, shift]) => {
          const shiftFromResponse = shiftsFromResponse[draftKey];

          // Build error message from response and generate error status
          const errorMessage = buildMessage(shiftFromResponse);
          let status = null;
          if (shiftFromResponse.clashingShifts) status = 'shiftClash';
          else if (shiftFromResponse.error) status = 'Failed';

          const draftWithResponseData = addResponseDataToDraftShift(shift, errorMessage, status);
          draftShiftsWithResponseData[draftKey] = draftWithResponseData;
        });

        dispatch(createShiftsActions.addMultipleDraftShifts(draftShiftsWithResponseData));
        dispatch(createShiftsActions.validationError(response.humanReadableErrorMessage));
      }

    } catch (error) {
      // If unexpected error, save to Redux
      reportError(error);
      dispatch(createShiftsActions.createShiftsError(error.message));
    }
  };
}

function addDefaultPropsToNewShift({ user, rgs, global, selectedFilterItems }) {

  const { orgConfig, adminBanks, services } = global;
  const hasPreferredGender = orgConfig?.preferredGender?.enabled;
  const preferredGender = orgConfig?.preferredGender;
  const servicesEnabled = isFeatureOn(featureFlags.SERVICES, null, user, global);
  const customHourlyRateEnabled = isFeatureOn(featureFlags.CUSTOM_HOURLY_RATE, null, user, global);

  const newShift = {
    key: uuidv4(),
    slotsRequired: 1,
    candidates: [],
  };

  const rgsMetadata = rgs.rgsMetadata;
  const keyedServices = keyBy(services, 'key');

  // Set default service and default banks based on service if feature is enabled and there is only one service to select
  if (servicesEnabled) {

    if (services.length === 1) {
      const defaultService = services[0];
      newShift.service = { id: defaultService.key, name: defaultService.name };
      newShift.bankKeys = Object.entries(defaultService.banks).filter(([, details]) => details.releaseToByDefault).map(([bankKey]) => bankKey);
    } else {
      newShift.bankKeys = null;
    }

  } else {
    // Otherwise set banks to default org banks
    newShift.bankKeys = adminBanks.filter(bank => bank.defaultBank).map(bank => bank.bankKey);
  }

  // Determine default gender
  if (hasPreferredGender) { newShift.preferredGender = preferredGender.default; }

  // Determine default site
  const selectedFilterSiteKey = selectedFilterItems?.sites?.[0];
  const sortedUserSites = Object.entries(user.sites)
    .filter(([, siteDetails]) => siteDetails.canManageShifts)
    .map(([key, siteDetails]) => ({ key, name: siteDetails.name, timezone: siteDetails.timezone })).sort(firstBy('name'));

  const site = selectedFilterSiteKey && user.sites[selectedFilterSiteKey]?.canManageShifts ? user.sites[selectedFilterSiteKey] : sortedUserSites[0];
  newShift.site = { id: site.key, name: site.name };

  // For each field: match the filters if set to something specific, else default to Any (if field not mandatory) or first in list (if it is mandatory)
  const mandatoryFields = global?.orgConfig?.shiftMandatoryFields ?? [];
  const roleIsMandatory = mandatoryFields.includes('role');
  const gradeIsMandatory = mandatoryFields.includes('grade');
  const specialityIsMandatory = mandatoryFields.includes('speciality');
  const serviceMetadata = keyedServices?.[newShift.service?.id] ?? null;

  // Compute selected roles (based on service rgs) or fallback to all roles (used for role, grade, and speciality computations)
  const roleOptions = getRoleOptions(serviceMetadata, rgsMetadata);

  // Determine default role
  const roleKey = selectedFilterItems?.roles?.[0] ?? (roleIsMandatory ? roleOptions[0].value : null);
  const role = roleKey && rgsMetadata[roleKey];
  newShift.role = role ? { id: roleKey, name: role.name } : null;

  // Determine default grade
  const selectedGradeFilterKey = role && selectedFilterItems?.grades?.filter(key => !!role.grades[key])[0];
  const gradeOptions = getGradeOptions(serviceMetadata, newShift.role, rgsMetadata);
  const gradeKey = role && (selectedGradeFilterKey ?? (gradeIsMandatory ? Object.values(gradeOptions).sort(firstBy('order'))[0]?.key : null));
  const grade = gradeKey && gradeOptions[gradeKey];
  newShift.grade = grade ? { id: gradeKey, name: grade.name } : null;

  // Determine default speciality
  const selectedSpecialityFilterKey = selectedFilterItems?.specialities?.[0];
  const specialityOptions = getSpecialityOptions(serviceMetadata, newShift.role, rgsMetadata);
  const specialityKey = selectedSpecialityFilterKey ?? (specialityIsMandatory ? Object.values(specialityOptions).sort(firstBy('order'))[0]?.key : null);
  const speciality = specialityKey && specialityOptions[specialityKey];
  newShift.speciality = speciality ? { id: specialityKey, name: speciality.name } : null;

  if (customHourlyRateEnabled) {
    newShift.customHourlyRate = '';
  }

  return [newShift, site.timezone];
}

export function createDraftShift(selectedDate) {
  return async (dispatch, getState) => {

    const draftId = uuidv4();
    const { user, rgs, global, filter } = getState();
    const selectedFilterItems = filter.v2SelectedItems?.shifts;

    // Add default props that are shared between both draft and template shifts
    const [newShift, timezone] = addDefaultPropsToNewShift({ user, rgs, global, selectedFilterItems });

    // Add default startTime and endTime
    newShift.startTime = convertFromUtcToTimezone(selectedDate, timezone).hour(7).minute(30).toISOString();
    newShift.endTime = convertFromUtcToTimezone(selectedDate, timezone).hour(20).minute(0).toISOString();

    dispatch(createShiftsActions.addDraftShift(draftId, newShift));

    return draftId;
  };
}

export function createTemplateShift() {
  return async (dispatch, getState) => {

    const templateShiftId = uuidv4();
    const { user, rgs, global, templates } = getState();

    // Add default props that are shared between both draft and template shifts
    const [newShift] = addDefaultPropsToNewShift({ user, rgs, global, selectedFilterItems: null });

    // Add default startTimeOfDay, endTimeOfDay and dayOfWeek
    newShift.startTimeOfDay = '07:30';
    newShift.endTimeOfDay = '20:00';
    newShift.dayOfWeek = templates.template?.periodType !== 'day' ? { id: 'mon', name: 'Monday' } : null;

    dispatch(createShiftsActions.addTemplateShift(templateShiftId, newShift));
    dispatch(templateActions.setTemplateEdited());

    return templateShiftId;
  };
}

export function copyAndCreateTemplateShift(copyShiftKey) {
  return async (dispatch, getState) => {
    const templateShiftId = uuidv4();
    const { createShifts } = getState();
    dispatch(createShiftsActions.addTemplateShift(templateShiftId, createShifts.templateShifts[copyShiftKey]));
    dispatch(templateActions.setTemplateEdited());
    return templateShiftId;
  };
}

export function copyAndCreateDraftShift(copyShiftKey) {
  return (dispatch, getState) => {

    const draftId = uuidv4();
    const { jobs, createShifts, global } = getState();
    const { orgConfig, adminBanks, orgRateCards } = global;

    // If copied from draft shift, save to state and return draftId
    if (createShifts.draftShifts[copyShiftKey]) {
      dispatch(createShiftsActions.addDraftShift(draftId, createShifts.draftShifts[copyShiftKey]));
      return draftId;
    }

    // Otherwise shift is copied from published shift
    const newDraftShift = createDraftFromPublishedShift({ publishedShift: jobs.shiftList[copyShiftKey], orgRateCards, preferredGender: orgConfig?.preferredGender, adminBanks, saveCandidate: true });
    dispatch(createShiftsActions.addDraftShift(draftId, newDraftShift));

    return draftId;
  };
}

export function refreshRecentlyCreatedShifts() {
  return async (dispatch, getState) => {
    const recentlyCreatedShiftKeys = Object.keys(getState().createShifts.recentlyCreatedShifts);
    if (!recentlyCreatedShiftKeys.length) return;

    const response = await api.post('jobs/shift-list', { shiftKeys: recentlyCreatedShiftKeys });

    if (response.status === 200 && response?.body?.shiftList) {
      dispatch(createShiftsActions.upsertRecentlyCreatedShifts(response.body.shiftList));
    } else {
      reportError(new Error('Error refreshing recently created shifts'), { response });
    }
  };
}

export function setRateCard(shiftId, shiftType) {
  return async (dispatch, getState) => {

    // Get current state
    const state = getState();
    const orgRateCards = state.global.orgRateCards;
    const currentOrgKey = state.global.currentOrgKey;
    const shift = state.createShifts[shiftType][shiftId];
    const selectedRateCardKey = shift.rateCard?.key;

    const selectedRateModifierKey = shift.rateCard?.rateModifierKey ?? null;
    const selectedTimesheetTypeKey = shift.rateCard?.timesheetTypeKey ?? null;

    // Find rate cards that match shift specification
    const applicableRateCards = findApplicableRateCards(orgRateCards, shift.role?.id, shift.speciality?.id, shift.grade?.id, shift.site?.id, shift.area?.id, shift.reason?.id, shift.reason2?.id, shift.service?.id, currentOrgKey);

    // Set selected rate card (if no selected rate card or selected rate card is no longer applicable)
    if (!selectedRateCardKey || !applicableRateCards.find(rateCard => rateCard.key === selectedRateCardKey)) {

      // If switching between grades the specific rate card may change, but it may represent the same configuration
      // This code is also used to map old-style ratecards to new-style ratecards
      const sameSettingsRateCard = applicableRateCards.find(rateCard => (selectedTimesheetTypeKey && rateCard.timesheetTypeKey === selectedTimesheetTypeKey) && (selectedRateModifierKey && rateCard.rateModifierKey === selectedRateModifierKey));
      dispatch(updateShiftProp(shiftId, 'rateCard', sameSettingsRateCard ?? applicableRateCards[0], shiftType));
    }

  };
}

export function setCostCentre(shiftId, shiftType) {
  return async (dispatch, getState) => {
    const shift = getState().createShifts[shiftType][shiftId];

    dispatch(updateShiftProp(shiftId, 'costCentre', null, shiftType));

    const costCentres = getState().global.shiftCreateMetadata?.orgCostCentres ?? [];

    const costCentre = fetchMatchingCostCentre(costCentres, shift.site?.id, shift.area?.id, shift.role?.id, shift.grade?.id, shift.speciality?.id, shift.reason?.id, shift.service?.id);
    if (costCentre) dispatch(updateShiftProp(shiftId, 'costCentre', { code: costCentre.code, description: costCentre.description, careGroup: costCentre.careGroup }, shiftType));
  };
}

function setApplicants(shiftId, shiftType) {
  return async (dispatch, getState) => {
    const shift = getState().createShifts[shiftType][shiftId];

    dispatch(updateShiftProp(shiftId, 'candidate', null, shiftType));
    dispatch(updateShiftProp(shiftId, 'targetedCandidateId', null, shiftType));

    await dispatch(fetchApplicableCandidates({
      startTime: shift.startTime,
      endTime: shift.endTime,
      siteKey: shift.site.id,
      roleKey: shift.role?.id ?? null,
      gradeKey: shift.grade?.id ?? null,
      specialityKey: shift.speciality?.id ?? null,
      serviceKey: shift?.service?.id ?? null,
    }));
  };
}

function formatTimeOfDay(time) {
  return `${time < 10 ? `0${time}` : time}`;
}

export function setSiteArea(siteKey, shiftId, shiftType) {
  return async (dispatch, getState) => {

    // Get areas from sites list
    const siteAreas = getState().user.sites[siteKey].areas ?? [];

    // Set default area
    const defaultArea = siteAreas.length > 0 ? { id: siteAreas[0].key, name: siteAreas[0].name } : null;
    dispatch(updateShiftProp(shiftId, 'area', defaultArea, shiftType));
  };
}

export function setRgsOptions(shiftId, shiftType, updatedShift, mandatoryFields) {
  return async (dispatch, getState) => {
    const state = getState();
    const rgsMetadata = state.rgs.rgsMetadata;

    const services = state.global.services;
    const shiftMandatoryFields = state.global?.orgConfig?.shiftMandatoryFields;

    const selectedServiceKey = updatedShift.service?.id;
    const selectedService = services.find(service => service.key === selectedServiceKey) ?? null;

    // Set grade and speciality options
    const roles = getRoleOptions(selectedService, rgsMetadata);
    const grades = getGradeOptions(selectedService, updatedShift.role, rgsMetadata);
    const specialities = getSpecialityOptions(selectedService, updatedShift.role, rgsMetadata);
    dispatch(createDraftShiftActions.setRoles(roles));
    dispatch(createDraftShiftActions.setGrades(grades));
    dispatch(createDraftShiftActions.setSpecialities(specialities));

    console.log(shiftMandatoryFields);
    // if grade is mandatory
    if (shiftMandatoryFields.includes('grades')) {
      const grade = getFirstGrade(updatedShift.grade?.id ?? null, grades);
      dispatch(updateShiftProp(shiftId, 'grade', grade, shiftType));
    }

    if (shiftMandatoryFields.includes('specialities')) {
      const speciality = getSpeciality(updatedShift.speciality?.id ?? null, specialities, mandatoryFields);
      dispatch(updateShiftProp(shiftId, 'speciality', speciality, shiftType));
    }
  };
}

export function updateShiftProp(shiftId, propKey, propVal, shiftType) {

  return async (dispatch, getState) => {

    // Get current state
    const { createShifts, global, user, templates } = getState();
    const shift = createShifts[shiftType][shiftId];
    const costCentresOn = isFeatureOn(featureFlags.COST_CENTRES, null, user, global);
    const shiftMandatoryFields = global?.orgConfig?.shiftMandatoryFields ?? [];
    const customHourlyRateCriteria = global.orgConfig?.customHourlyRateCriteria ?? {};
    const mandatoryFields = shiftType === 'draftShifts' ? shiftMandatoryFields : getTemplateMandatoryFields(shiftMandatoryFields, templates.template?.periodType);
    const updatedShift = { ...shift, [propKey]: propVal };

    // This array ensures we are not only checking mandatory fields for validation. slotsRequired will be added to orgConfig?.shiftMandatoryFields once this branch is merged into dev.
    // candidates will never be a mandatory field, however we need to check that there are not more candidates than slots required
    const fieldsToCheck = [...mandatoryFields, 'slotsRequired', 'candidates'];

    // Check validity
    const shiftIsValid = fieldsToCheck.every((field) => {
      const shiftFieldMapping = shiftFieldMappings[field];
      const draftValue = updatedShift[shiftFieldMapping?.key ?? field];

      // If rateCard is required, check if rateCard requires custom hourly rate field
      if (field === 'rateCard') {

        // If rate card does not require a custom rate, then just check existence of rate card
        if (!draftValue?.requireCustomHourlyRate) return !!draftValue;

        // Validate hourly rate
        const customHourlyRateValidation = validateHourlyRate(updatedShift.customHourlyRate, customHourlyRateCriteria);

        return !!draftValue && customHourlyRateValidation.success;
      }

      if (field === 'slotsRequired') {
        return draftValue >= 1 && draftValue < 100;
      }

      if (field === 'candidates') {
        return draftValue.length <= updatedShift.slotsRequired;
      }

      return Array.isArray(draftValue) ? !!draftValue.length : !!draftValue;
    });

    if (propKey === 'role') {
      // Set role
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, resetErrors: true, shiftType }));
      dispatch(setRgsOptions(shiftId, shiftType, updatedShift, mandatoryFields));
      dispatch(setApplicants(shiftId, shiftType));
      dispatch(setRateCard(shiftId, shiftType));
      if (costCentresOn) dispatch(setCostCentre(shiftId, shiftType));
    }
    else if (propKey === 'area') {
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, shiftType }));
      if (costCentresOn) dispatch(setCostCentre(shiftId, shiftType));
    }
    else if (propKey === 'grade') {
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, resetErrors: true, shiftType }));
      dispatch(setApplicants(shiftId, shiftType));
      dispatch(setRateCard(shiftId, shiftType));
      if (costCentresOn) dispatch(setCostCentre(shiftId, shiftType));
    }
    else if (propKey === 'speciality') {
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, resetErrors: true, shiftType }));
      dispatch(setApplicants(shiftId, shiftType));
      dispatch(setRateCard(shiftId, shiftType));
      if (costCentresOn) dispatch(setCostCentre(shiftId, shiftType));
    }
    else if (propKey === 'site') {
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, shiftType }));
      dispatch(setSiteArea(propVal.id, shiftId, shiftType));
      dispatch(setApplicants(shiftId, shiftType));
      dispatch(setRateCard(shiftId, shiftType));
      if (costCentresOn) dispatch(setCostCentre(shiftId, shiftType));
    }
    else if (propKey === 'date' || propKey === 'startTime' || propKey === 'endTime') {
      const currentStart = createShifts.draftShifts[shiftId].startTime;
      const currentEnd = createShifts.draftShifts[shiftId].endTime;
      const siteKey = createShifts.draftShifts[shiftId].site?.id;
      const timezone = user.sites[siteKey]?.timezone ?? 'Europe/London';

      const { newStartTime, newEndTime } = (() => {
        if (propKey === 'date') return computeNewShiftTimes({ currentStart, currentEnd, timezone, newDate: propVal });
        if (propKey === 'startTime') return computeNewShiftTimes({ currentStart, currentEnd, timezone, newStartTime: propVal });
        if (propKey === 'endTime') return computeNewShiftTimes({ currentStart, currentEnd, timezone, newEndTime: propVal });
        throw new Error('Impossible');
      })();

      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey: 'startTime', propVal: newStartTime, valid: shiftIsValid, resetErrors: true, shiftType }));
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey: 'endTime', propVal: newEndTime, valid: shiftIsValid, resetErrors: true, shiftType }));
    }
    else if (propKey === 'startTimeOfDay' || propKey === 'endTimeOfDay') {
      const hours = formatTimeOfDay(propVal.hours);
      const minutes = formatTimeOfDay(propVal.minutes);
      const timeOfDay = `${hours}:${minutes}`;
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal: timeOfDay, valid: shiftIsValid, resetErrors: true, shiftType }));
    }
    else if (propKey === 'reason') {
      // Update reason
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, shiftType }));
      // Clear selected sub-reason
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey: 'reason2', propVal: null, valid: shiftIsValid, shiftType }));
      if (costCentresOn) dispatch(setCostCentre(shiftId, shiftType));
    }
    else if (propKey === 'service') {
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, shiftType }));
      dispatch(setApplicants(shiftId, shiftType));
      const bankOptions = global.services.find(service => service.key === propVal.id).banks;
      const defaultBanks = Object.entries(bankOptions).filter(([, details]) => details.releaseToByDefault).map(([bankKey]) => bankKey);
      dispatch(updateShiftProp(shiftId, 'bankKeys', defaultBanks, shiftType));
      dispatch(setRgsOptions(shiftId, shiftType, updatedShift, mandatoryFields));
      if (costCentresOn) dispatch(setCostCentre(shiftId, shiftType));
      dispatch(setRateCard(shiftId, shiftType));
    }
    else {
      dispatch(createShiftsActions.updateShiftProp({ shiftId, propKey, propVal, valid: shiftIsValid, shiftType }));
    }
  };
}

export function fetchApplicableCandidates({ startTime, endTime, siteKey, roleKey, specialityKey, gradeKey, serviceKey }) {
  return async (dispatch, getState) => {

    const { global, user } = getState();
    const site = user.sites[siteKey];
    const showCandidateLocationsFeatureIsOn = isFeatureOn(featureFlags.SHOW_CANDIDATE_LOCATIONS_TO_ADMINS, null, user, global);
    const contractedHoursFeatureIsOn = isFeatureOn(featureFlags.CONTRACTED_HOURS, null, user, global);

    dispatch(createDraftShiftActions.fetchingApplicableCandidates());
    const candidates = await jobsApis.fetchApplicableCandidates({ startTime, endTime, siteKey, roleKey, gradeKey, specialityKey, serviceKey });

    const applicableCandidatesList = createApplicableCandidatesSelectList(candidates, site, showCandidateLocationsFeatureIsOn, contractedHoursFeatureIsOn);

    dispatch(createDraftShiftActions.setApplicableCandidates(applicableCandidatesList));
  };
}

// Populate form using data from existing draft shift
export function populateShiftFormData(draftId, shiftType) {
  return async (dispatch, getState) => {
    const { rgs, createShifts, global, user } = getState();
    const costCentresOn = isFeatureOn(featureFlags.COST_CENTRES, null, user, global);

    const shift = createShifts[shiftType][draftId];
    if (!shift) return;
    const { service, role, grade, speciality, site, startTime, endTime } = shift;

    const services = global.services;
    const selectedServiceKey = shift.service?.id;
    const selectedService = services.find(s => s.key === selectedServiceKey) ?? null;

    // Set grade and speciality options
    const roles = getRoleOptions(selectedService, rgs.rgsMetadata);
    const grades = getGradeOptions(selectedService, role, rgs.rgsMetadata);
    const specialities = getSpecialityOptions(selectedService, role, rgs.rgsMetadata);
    dispatch(createDraftShiftActions.setRoles(roles));
    dispatch(createDraftShiftActions.setGrades(grades));
    dispatch(createDraftShiftActions.setSpecialities(specialities));

    dispatch(fetchApplicableCandidates({
      startTime,
      endTime,
      siteKey: site.id,
      roleKey: role?.id ?? null,
      specialityKey: speciality?.id ?? null,
      gradeKey: grade?.id ?? null,
      serviceKey: service?.id ?? null,
    }));
    if (costCentresOn) dispatch(setCostCentre(draftId, shiftType));
    dispatch(setRateCard(draftId, shiftType));
  };
}

export function applyTemplate() {
  return async (dispatch, getState) => {
    const { createShifts, templates, user, global } = getState();

    const templateShifts = createShifts.templateShifts;
    const adminSites = user.sites;
    const orgTimezone = global.orgConfig?.timezone;

    if (Object.keys(templateShifts).length === 0) throw new Error('No shifts to apply to template');
    if (!createShifts.applyTemplateToDate) throw new Error('No date to apply shifts to');
    if (!templates.template) throw new Error('No template selected');

    const periodType = templates.template.periodType;
    const newDraftShifts = {};

    Object.values(templateShifts).forEach((shift) => {

      const { key, startTimeOfDay, endTimeOfDay, dayOfWeek, ...draftShiftProps } = shift;
      const timezone = (shift.site?.id && adminSites[shift.site?.id]?.timezone) ?? orgTimezone;

      const [startTime, endTime] = calculateStartAndEndTimesFromTemplateShift({ date: createShifts.applyTemplateToDate.toISOString(), startTimeOfDay, endTimeOfDay, periodType, dayOfWeek: dayOfWeek?.id ?? null, timezone });
      const draftShiftKey = uuidv4();

      newDraftShifts[draftShiftKey] = { ...draftShiftProps, startTime, endTime };
    });

    dispatch(createShiftsActions.addMultipleDraftShifts(newDraftShifts));
    dispatch(createShiftsActions.clearApplyTemplate());
    dispatch(createShiftsActions.clearTemplateShifts());
  };
}
