import React from 'react';
// import { MdFilterList } from 'react-icons/md';

import colors from 'config/colors';
import spacing from 'config/spacing';
// import { capitalise } from 'lib/helpers-text';

import { useFilters } from 'hooks/filters';
import { CWFilterSelect } from '../CWSelect';
import './FilterBar.css';

const styles = {
  container: {
    zIndex: 100,
    overflow: 'visible',
    width: '100%',
    display: 'flex',
    borderTopRightRadius: spacing.tiny,
    borderTopLeftRadius: spacing.tiny,
  },
  filterContainer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    flex: '0 0 auto',
  },
  filterBarFiltersContainer: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 12px',
    fontSize: '1rem',
    fontWeight: 600,
    color: colors.text,
  },
};

function humanizeCamelCase(str) {
  return str
    // Insert a space before each uppercase letter
    .replace(/([A-Z])/g, ' $1')
    // Capitalize the first character
    .replace(/^./, char => char.toUpperCase())
    // Remove any leading/trailing spaces
    .trim();
}

const FilterBar = (props) => {

  const [filterConfig, selectedItems, setSelectedItems] = useFilters(props.namespace, props.filterConfigKey);
  return (
    <div className="filterBar p-hide" style={{ ...styles.container, backgroundColor: props.backgroundColor }}>

      {/* Filter bar buttons */}
      <div className="filterBarButtonContainer" style={styles.buttonContainer}>
        {props.leftButtons || null}
      </div>

      {/* Filters with label */}
      <div className="filterBarFiltersContainer hideIeScrolls" style={styles.filterContainer}>

        {/* Filters applied label */}
        {props.showLabel && (
          <div style={styles.filterBarFiltersContainer}>
            Filters:
          </div>
        )}

        {/* Filters */}
        {filterConfig.map((filter) => {
          return (
            <div style={{ padding: 6, paddingRight: 3, paddingLeft: 3 }}>
              <CWFilterSelect
                key={filter.key}
                items={filter.options}
                selectedItems={selectedItems?.[filter.key]}
                setSelectedItems={items => setSelectedItems(props.namespace, filter.key, items)}
                noneSelectedLabel={humanizeCamelCase(filter.key)}
                truncateButtonLabel
              />
            </div>
          );
        })}
      </div>

      {/* Filter bar buttons */}
      {/* <div className="filterBarButtonContainer m-hide" style={styles.buttonContainer}>
        {props.buttons || null}
      </div> */}
    </div>
  );
};

FilterBar.defaultProps = { showLabel: true };

export default FilterBar;
